.title .description {
    text-align: center;
}

.title {
    font-size: 4rem;
    margin-left: 0;
}

.mainContainer {
    scroll-snap-type: y mandatory;
}

.content {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.introductionLeftAlign {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start
}

.introductionRightAlign {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end
}