/*ubuntu bold 700 font*/
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap');

html,body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Ubuntu;
}