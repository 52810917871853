@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap);
.title .description {
    text-align: center;
}

.title {
    font-size: 4rem;
    margin-left: 0;
}

.mainContainer {
    -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
}

.content {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.introductionLeftAlign {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start
}

.introductionRightAlign {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end
}
/*ubuntu bold 700 font*/

html,body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Ubuntu;
}
